import axios from 'axios';
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as CONSTANTS from '../../CONSTANTS'
import { Button, message  ,Pagination,Skeleton} from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { ProfileElement } from './ProfileElement';
import 'antd/dist/antd.css'
import { LoadingOutlined } from '@ant-design/icons/LoadingOutlined';
import { Col, Row } from 'react-bootstrap';
import Upload from 'antd/es/upload';
export default function Product({ token }) {
	const tableRef = useRef(null);
	const [key , setKey] = useState(1);
var params = useParams();
	const {t} = useTranslation('home');
    const [load , setLoading] = useState(true);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);
    const [total , setTotal] = useState(0);
    const [page , setCurrent] = useState(1);
    const [searchWord , setSearchWord] = useState(null);

	const [plans , setPlans] = useState([]);
	const [loading, setLoadings] = useState(false);
	const [profilePic, setProfilePic] = useState(null);
    const [appointments , setAppointmentsData] = useState(null);
	const [uploadId, setUploadId] = useState(false);

	const [imageUrl, setImageUrl] = useState();
	const [progress, setProgress] = useState(0);
	const [name, setName] = useState('');
	const [name_ar, setName_ar] = useState('');
	const [price, setPrice] = useState('');
	const [chronic, setCronic] = useState(false);
	const [type, setType] = useState('Local');

	const [serialNumber, setSerialNumber] = useState('');

	
	const saveProduct = ()=>{
		let data = JSON.stringify({
			 
			  "name": name,
			  "name_ar":name_ar,
			  "price":price,
			  "chronic":chronic,
			  "serialNumber":serialNumber,
			  "type":type,


			
		  });
		const headers =  {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			"content-type": "multipart/form-data"
		};
		axios.put(CONSTANTS.DEFAULT_URL+'supplier-products/'+params.id , {data : data}
		, {
			headers: headers
		  })
.then(function (response) {
 if(response.status === 200){
message.success("Upadted Successfully");
 }else{
	message.error("Can't update");

 }

})
.catch(function (error) {
	navigate('/login');

setLoading(true);

if(error.response.status === 403){
	navigate('/login');


}
  console.log(error);
})
	}
	const uploadImage = async options => {
		const { onSuccess, onError, file, onProgress } = options;
		console.log(file);

		const fmData = new FormData();
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
				"content-type": "multipart/form-data"
			},
			onUploadProgress: event => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent);
				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000);
				}
				onProgress({ percent: (event.loaded / event.total) * 100 });
			}
		};
		fmData.append("files", file);
		try {
			const res = await axios.post(
				CONSTANTS.DEFAULT_URL + "upload",
				fmData,
				config
			);

			onSuccess("Ok");
			console.log("server res: ", res.data[0].id);
			//profilePicture
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token
			}

				.then(function (response) { });
			setUploadId(res.data[0].id);

		} catch (err) {
			console.log("Eroor: ", err);
			const error = new Error("Some error");
			onError({ err });
		}
	};
	const handleChange = (info) => {
		if (info.file.status === 'uploading') {
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (url) => {
				setProfilePic(url);
			});
		}
	};
	const updatePage = title => {
		setLoading(true);


		setAppointmentsLoading(true);
		setLoading(false);
	 }
    const navigate = useNavigate();
    useEffect(() => {
setLoading(true);
        if(token != null ){
            setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
            axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
                headers: headers
              })
    .then(function (response) {
      // handle success
    //   http://3.70.182.78:1337/api/orders?filters[patient][id]=1007
	var query = '';
	setLoading(true);
	

    axios.get(CONSTANTS.DEFAULT_URL+'supplier-products/'+params.id+'?populate=image&filters[$and][0][product_category][name][$eq]=pharmacy' , {
        headers: headers
      }).then(function (appointmentData){
            setAppointmentsData(appointmentData.data.data);
			setName(appointmentData.data.data.attributes.name);
			setName_ar(appointmentData.data.data.attributes.name_ar);
			setPrice(appointmentData.data.data.attributes.price);
			setSerialNumber(appointmentData.data.data.attributes.serialNumber);
			setCronic(appointmentData.data.data.attributes.chronic);


			
            setAppointmentsLoading(true);
		setLoading(false);
      })


        

    })
    .catch(function (error) {
        navigate('/login');

    setLoading(true);

    if(error.response.status === 403){
        navigate('/login');


    }
      console.log(error);
    })

}else{
    navigate('/login');

    setLoading(true);

}

}, [page]);
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const uploadButton = (
    <div>
        {loading ? <LoadingOutlined /> : <div class="upload-img">
            <div class="change-photo-btn">
                <span><i class="fa fa-upload"></i>{t('Change Product Picture')}</span>
                <input type="file" class="upload" />
            </div>
        </div>}
        {/* <div class="upload-img">
                                                        <div class="change-photo-btn">
                                                            <span><i class="fa fa-upload"></i>{t('Change Profile Picture')}</span>
                                                            <input type="file" class="upload" />
                                                        </div>
                                                    </div> */}
    </div>
);

  return (

         <div className='container-fluid'>

  <Suspense>
{load === false ? (<><div class="breadcrumb-bar">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-12 col-12">
							<nav aria-label="breadcrumb" class="page-breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home </a></li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
            <div class="content">
				<div class="container-fluid">
					<div class="row">
					
						
						<div class="col-md-12">
			
					<div  class="tab-pane fade show active">
								<div class="card card-table mb-0">


									<div class="card-body">
                                        {/* <div class="form-group">
                                            <label>Image</label>
                                            <Upload
																	accept="image/*"
																	customRequest={uploadImage}
																	onChange={handleChange}
																	listType="picture-card"
																	className="image-upload-grid"
																>
																	{imageUrl ? (
																		<></>
																	) : (
																		uploadButton
																	)}
																</Upload>


                                        </div> */}

                          
                                        <div class="form-group">
                                            <label>Name ar</label>
                                        <input type={'text'} className='form-control' value={name_ar} onChange={(e)=>{setName_ar(e.target.value)}} />


                                        </div>

                                        <div class="form-group">
                                        <label>Name en</label>

                                        <input type={'text'} className='form-control' value={name} onChange={(e)=>{setName(e.target.value)}}/>


                                        </div>

                                        <div class="form-group">
                                        <label>Price </label>

                                        <input type={'text'} className='form-control' value={price} onChange={(e)=>{setPrice(e.target.value)}} />


                                        </div>


                                        <div class="form-group">
                                        <label>Serial Number </label>

                                        <input type={'text'} className='form-control' value={serialNumber} onChange={(e)=>{setSerialNumber(e.target.value)}} />


                                        </div>
                                        <div class="form-group">
                                        <label>Cronic </label><br/>

                                         <input type='radio' checked={chronic === true ? true:false }  name='Cronic' onChange={()=>{setCronic(true)}} value='1' />YES 
                                         <input type='radio' checked={chronic === false ? true:false }   name='Cronic' onChange={()=>{setCronic(false)}}  value='0' />NO


                                        </div>

										<div class="form-group">
                                        <label>Cronic </label><br/>

                                         <input type='radio' checked={type === true ? true:false }  name='type' onChange={()=>{setCronic('Imported')}} value='Imported' />Imported 
                                         <input type='radio' checked={type === false ? true:false }   name='type' onChange={()=>{setCronic('Local')}}  value='Local' />Local


                                        </div>


{/* 
                                        <div class="form-group">
                                        <label>Imported </label>

                                         <input type='radio' name='imported'  value='1' />  YES //  
                                         <input type='radio'  name='imported'  value='0' />NO

            
                                        </div> */}

										<div class="form-group">
                                      <button className='btn btn-success' onClick={()=>{saveProduct()}}>Save</button>
            
                                        </div>
										
									</div>
								</div>
							</div>
						
				
							</div>	
							
					</div>
				</div>

			</div></>) : (<Skeleton active />)}

	  </Suspense>
		</div>

  )
}
