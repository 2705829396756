import axios from 'axios';
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as CONSTANTS from '../../CONSTANTS'
import { Button, message  ,Pagination,Skeleton , Select} from 'antd';
import { useTranslation } from 'react-i18next';

import 'antd/dist/antd.css'
import { LoadingOutlined } from '@ant-design/icons/LoadingOutlined';

let timeout;
let currentValue;
const fetch = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  const fake = () => {

    axios.get(CONSTANTS.DEFAULT_URL+`supplier-products?filters[name][$startsWith]=${value}`)
    //   .then((response) => response.json())
      .then((d) => {
        if (currentValue === value) {
            console.log(d);
          const  data  = d.data;
     
          const data2 = data.data.map((item) => ({
            value: item.id,
            text: item.attributes.name,
          }));
          callback(data2);
        }
      });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};

const fetch2 = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  const fake = () => {

    axios.get(CONSTANTS.DEFAULT_URL+`profiles?&filters[$or][0][$or][0][firstname][$containsi]=${value}&filters[$or][0][$or][1][lastname][$containsi]=${value}
    &filters[$or][0][$or][2][user][mobileNumber][$containsi]=${value}&filters[$or][0][$or][3][user][email][$containsi]=${value}&filters[$or][1][$and][0][firstname][$containsi]=${value}
    &filters[$or][1][$and][1][lastname][$containsi]=&populate[profilePicture]=*&populate[patient]=*&populate[user][role]=*&sort=[user][createdAt]:desc&filters[user][role]=4`)
    //   .then((response) => response.json())
      .then((d) => {
        if (currentValue === value) {
            console.log(d);
          const  data  = d.data;
     
          const data2 = data.data.map((item) => ({
            value: item.attributes.patient.data.id,
            text: item.attributes.firstname+' '+ item.attributes.lastname,
          }));
          callback(data2);
        }
      });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
export default function Pharmacy({ token }) {
	const tableRef = useRef(null);
	const [key , setKey] = useState(1);
var params = useParams();
	const {t} = useTranslation('home');
    const [load , setLoading] = useState(true);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);
    const [total , setTotal] = useState(0);
    const [page , setCurrent] = useState(1);
    const [searchWord , setSearchWord] = useState(null);

	const [plans , setPlans] = useState([]);
	const [loading, setLoadings] = useState(false);
	const [profilePic, setProfilePic] = useState(null);
    const [appointments , setAppointmentsData] = useState(null);
	const [uploadId, setUploadId] = useState(false);

	const [imageUrl, setImageUrl] = useState();
	const [progress, setProgress] = useState(0);
	const [name, setName] = useState('');
	const [name_ar, setName_ar] = useState('');
	const [price, setPrice] = useState('');
	const [chronic, setCronic] = useState(false);
	const [type, setType] = useState('Local');

	const [serialNumber, setSerialNumber] = useState('');

	
	const savePharmacy = ()=>{
		let data = JSON.stringify({
			 
			  "name": name,
			  "name_ar":name_ar,
              'favorite_products':favorite_products,
              'patients':favorite_patients,
			


			
		  });
		const headers =  {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			"content-type": "multipart/form-data"
		};
		axios.put(CONSTANTS.DEFAULT_URL+'suppliers/'+params.id , {data : data}
		, {
			headers: headers
		  })
.then(function (response) {
 if(response.status === 200){
message.success("Upadted Successfully");
 }else{
	message.error("Can't update");

 }

})
.catch(function (error) {
	navigate('/login');

setLoading(true);

if(error.response.status === 403){
	navigate('/login');


}
  console.log(error);
})
	}
	const uploadImage = async options => {
		const { onSuccess, onError, file, onProgress } = options;
		console.log(file);

		const fmData = new FormData();
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
				"content-type": "multipart/form-data"
			},
			onUploadProgress: event => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent);
				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000);
				}
				onProgress({ percent: (event.loaded / event.total) * 100 });
			}
		};
		fmData.append("files", file);
		try {
			const res = await axios.post(
				CONSTANTS.DEFAULT_URL + "upload",
				fmData,
				config
			);

			onSuccess("Ok");
			console.log("server res: ", res.data[0].id);
			//profilePicture
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token
			}

				.then(function (response) { });
			setUploadId(res.data[0].id);

		} catch (err) {
			console.log("Eroor: ", err);
			const error = new Error("Some error");
			onError({ err });
		}
	};
	const handleChange = (info) => {
		if (info.file.status === 'uploading') {
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (url) => {
				setProfilePic(url);
			});
		}
	};
	const updatePage = title => {
		setLoading(true);


		setAppointmentsLoading(true);
		setLoading(false);
	 }
    const navigate = useNavigate();
    useEffect(() => {
setLoading(true);
        if(token != null ){
            setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
            axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
                headers: headers
              })
    .then(function (response) {
      // handle success
    //   http://3.70.182.78:1337/api/orders?filters[patient][id]=1007
	var query = '';
	setLoading(true);
	

    axios.get(CONSTANTS.DEFAULT_URL+'suppliers/'+params.id+'?populate[favorite_products]=*&populate[patients][populate][profile]=*' , {
        headers: headers
      }).then(function (appointmentData){
            setAppointmentsData(appointmentData.data.data);
            const data = appointmentData.data.data.attributes.favorite_products.data.map((item) => ({
              value: item.id,
              text: item.attributes.name,
            }));
            setData(data);
            let favP = [];
            data.forEach(element => {
              favP.push(element.value)
            });
            setFavorite_products(favP);



            const data2 = appointmentData.data.data.attributes.patients.data.map((item) => ({
              value: item.id,
              text: item.attributes.profile.data.attributes.firstname+' '+item.attributes.profile.data.attributes.lastname,
            }));
            setData2(data2);
            let favP2 = [];
            data2.forEach(element => {
              favP2.push(element.value)
            });
            setFavorite_patients(favP2);
            
			setName(appointmentData.data.data.attributes.name);
			setName_ar(appointmentData.data.data.attributes.name_ar);

			
            setAppointmentsLoading(true);
		setLoading(false);
      })


        

    })
    .catch(function (error) {
        navigate('/login');

    setLoading(true);

    if(error.response.status === 403){
        navigate('/login');


    }
      console.log(error);
    })

}else{
    navigate('/login');

    setLoading(true);

}

}, [page]);
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const uploadButton = (
    <div>
        {loading ? <LoadingOutlined /> : <div class="upload-img">
            <div class="change-photo-btn">
                <span><i class="fa fa-upload"></i>{t('Change Product Picture')}</span>
                <input type="file" class="upload" />
            </div>
        </div>}
        {/* <div class="upload-img">
                                                        <div class="change-photo-btn">
                                                            <span><i class="fa fa-upload"></i>{t('Change Profile Picture')}</span>
                                                            <input type="file" class="upload" />
                                                        </div>
                                                    </div> */}
    </div>
);
const [data, setData] = useState([]);
  const [favorite_products, setFavorite_products] = useState();
  const handleSearch = (newValue) => {
    fetch(newValue, setData);
  };
  const handleSearch3 = (newValue) => {
    fetch2(newValue, setData2);
  };
  const handleChange2 = (newValue) => {
    setFavorite_products(newValue);


    console.log(newValue);
  };


  const [data2, setData2] = useState([]);
  const [favorite_patients, setFavorite_patients] = useState();

  const handleChange3 = (newValue) => {
    setFavorite_patients(newValue);


  };
  return (

         <div className='container-fluid'>

  <Suspense>
{load === false ? (<><div class="breadcrumb-bar">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-12 col-12">
							<nav aria-label="breadcrumb" class="page-breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home </a></li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
            <div class="content">
				<div class="container-fluid">
					<div class="row">
					
						
						<div class="col-md-12">
			
					<div  class="tab-pane fade show active">
								<div class="card card-table mb-0">


									<div class="card-body">
                                        {/* <div class="form-group">
                                            <label>Image</label>
                                            <Upload
																	accept="image/*"
																	customRequest={uploadImage}
																	onChange={handleChange}
																	listType="picture-card"
																	className="image-upload-grid"
																>
																	{imageUrl ? (
																		<></>
																	) : (
																		uploadButton
																	)}
																</Upload>


                                        </div> */}

                          
                                        <div class="form-group">
                                            <label>Name ar</label>
                                        <input type={'text'} className='form-control' value={name_ar} onChange={(e)=>{setName_ar(e.target.value)}} />


                                        </div>

                                        <div class="form-group">
                                        <label>Name en</label>

                                        <input type={'text'} className='form-control' value={name} onChange={(e)=>{setName(e.target.value)}}/>


                                        </div>
                                        <div class="form-group col-6">
                                        <label>Favorite Products</label>

                                        <Select
      showSearch
      value={favorite_products}
      mode='tags'
      style={{width:'400px'}}
      placeholder={''}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange2}
      notFoundContent={null}
      tokenSeparators={[',']}

      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
</div>



                                                        <div class="form-group col-6">
                                        <label>Favorite Patients</label>

                                        <Select
      showSearch
      value={favorite_patients}
      mode='tags'
      style={{width:'400px'}}
      placeholder={''}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch3}
      onChange={handleChange3}
      notFoundContent={null}
      tokenSeparators={[',']}

      options={(data2 || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
</div>             





										<div class="form-group">
                                      <button className='btn btn-success' onClick={()=>{savePharmacy()}}>Save</button>
            
                                        </div>
										
									</div>
								</div>
							</div>
						
				
							</div>	
							
					</div>
				</div>

			</div></>) : (<Skeleton active />)}

	  </Suspense>
		</div>

  )
}
