import axios from 'axios';
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import * as CONSTANTS from '../../CONSTANTS'
import { Button, message  ,Pagination,Skeleton} from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { ProfileElement } from './ProfileElement';
import 'antd/dist/antd.css'
import { Col, Row } from 'react-bootstrap';

export const Home = ({token}) => {
	const tableRef = useRef(null);
	const [key , setKey] = useState(1);

	const {t} = useTranslation('home');
    const [load , setLoading] = useState(true);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);
    const [total , setTotal] = useState(0);
    const [page , setCurrent] = useState(1);
    const [searchWord , setSearchWord] = useState(null);

	const [plans , setPlans] = useState([]);

    const [appointments , setAppointmentsData] = useState([]);
	const updatePage = title => {
		setLoading(true);


		setAppointmentsLoading(true);
		setLoading(false);
	 }
    const navigate = useNavigate();
    useEffect(() => {
setLoading(true);
        if(token != null ){
            setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
            axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
                headers: headers
              })
    .then(function (response) {
      // handle success
    //   http://3.70.182.78:1337/api/orders?filters[patient][id]=1007
	var query = '';
	setLoading(true);
	if(searchWord){
query += '&filters[name][$startsWith]='+searchWord;
	}
	// supplier-products?populate[image][fields]=name,url&populate[product_category][fields]=name&filters[supplier][type]=pharmacy&pagination[pageSize]=10&filters[name][$startsWith]=p
	axios.get(CONSTANTS.DEFAULT_URL+'supplier-products?pagination[page]='+page+'&pagination[pageSize]=30&populate[image][fields]&filters[supplier][type]=pharmacy'+query , {
        headers: headers
      }).then(function (appointmentData){
console.log(appointmentData.data.data);
setTotal(appointmentData.data.meta.pagination.total);
            setAppointmentsData(appointmentData.data.data);

            setAppointmentsLoading(true);
		setLoading(false);
      })


        

    })
    .catch(function (error) {
        navigate('/login');

    setLoading(true);

    if(error.response.status === 403){
        navigate('/login');


    }
      console.log(error);
    })

}else{
    navigate('/login');

    setLoading(true);

}

}, [page]);
	
const hideProduct = (id)=>{
	let data = JSON.stringify({
		 
		"publishedAt":null
		 


		
	  });
	const headers =  {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer ' + token,
		"content-type": "multipart/form-data"
	};
	axios.put(CONSTANTS.DEFAULT_URL+'supplier-products/'+id , {data : data}
	, {
		headers: headers
	  })
.then(function (response) {
if(response.status === 200){
message.success("Upadted Successfully");
}else{
message.error("Can't update");

}

})
.catch(function (error) {
navigate('/login');

setLoading(true);

if(error.response.status === 403){
navigate('/login');


}
console.log(error);
})
}
const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  const setSearchName = (word) => {
    console.log(word);
	setSearchWord(word);

  };
  const searchFunction = ()=>{
	setLoading(true);
	setCurrent(1);

	const headers = {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer '+token
	  }
	var query = '';
	if(searchWord){
		query += '&filters[name][$startsWith]='+searchWord;
			}
		
			axios.get(CONSTANTS.DEFAULT_URL+'supplier-products?pagination[page]='+page+'&pagination[pageSize]=50&populate[image][fields]&filters[supplier][type]=pharmacy'+query , {
				headers: headers
			  }).then(function (appointmentData){
		console.log(appointmentData.data.data);
		setTotal(appointmentData.data.meta.pagination.total);
					setAppointmentsData(appointmentData.data.data);
		
					setAppointmentsLoading(true);
				setLoading(false);
			  })
  }

  return (

         <div className='container-fluid'>

  <Suspense>
{load === false ? (<><div class="breadcrumb-bar">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-12 col-12">
							<nav aria-label="breadcrumb" class="page-breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home</a></li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
            <div class="content">
				<div class="container-fluid">
					<div class="row">
					
						
						<div class="col-md-12">
		

                   <Link class="btn btn-success" to={'/addProduct'}> {t('Add Product')} </Link>
			


				<br/>
				   <br/>
				   <Row>
				 <Col md={6}>
				 <input type={'text'} placeholder='Search' value={searchWord} className='form-control' onChange={(e)=>{setSearchName(e.target.value)}} />
				 </Col>
				 <Col md={3}>
				 <button className='btn btn-success' onClick={()=>{searchFunction(1)}}>Search</button>				 </Col>
				  

				   </Row>
					<div  class="tab-pane fade show active">
								<div class="card card-table mb-0">
									<div class="card-body">
										<div class="table-responsive">
											<table class="table table-hover table-center mb-0" ref={tableRef}> 
												<thead>
													<tr>
														<th>{t('Name')}</th>
														<th>{t('Price')}</th>
														<th>{t('Type')}</th>
														<th>{t('chronic')}</th>

														<th>{t('Edit')}</th>
														<th>{t('Hide')}</th>

														
														<th></th>
													</tr>
												</thead>
												<tbody>
											
				{appointmentsLoad ? (
appointments.length > 0 ? (
<>
	{appointments.map((profile, i) => {
	 
	  return (
	 <><tr>
	  <td>{profile.attributes.name}</td>
	  <td>{profile.attributes.price}</td>
	  <td>{profile.attributes.type}</td>
	  <td>{profile.attributes.chronic}</td>
	  <td><Link to={'/product/'+profile.id}>Edit</Link></td>

	  <td><a href="javascript:void(0)" class="text-danger" onClick={()=>hideProduct(profile.id)}>Hide</a></td>

	  </tr>
	 </>)
	}
	)}
	<Pagination defaultCurrent={1} current={page} total={total} onChange={onChange} /></>) : (
	  <>No Data</>
	)
  ) : (
	<>
	<Skeleton  avatar
paragraph={{
rows: 4,
}} active />
   </>
  )}
					
									
					</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						
				
							</div>	
							
					</div>
				</div>

			</div></>) : (<Skeleton active />)}

	  </Suspense>
		</div>

  )
}
